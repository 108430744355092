import { CheckCircleFilled, WarningFilled } from '@ant-design/icons';
import { notification, Spin, Tabs, TabsProps } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import Slider from 'components/Slider';
import { SupportModal } from 'components/SupportModal';
import { AnalyticsEvent } from 'entities/analytics';
import { DICTIONARY_BILLING_CYCLE, TariffPlanType, TariffPricingBillingCycle } from 'entities/tariffs';
import useToggleState from 'hooks/useToggleState';
import { useWidth } from 'hooks/useWidth';
import { TariffModel } from 'models/TariffModel';
import { useRootStore } from 'stores/RootStore';
import { useTariffsStore } from 'stores/TariffsStore/context';
import { numberWithSpaces } from 'utils/function';

import { TariffCard } from '../TariffItem/TariffItem';

import s from './TariffsList.module.scss';

const itemsTabs: TabsProps['items'] = [
  {
    key: TariffPlanType.personal,
    label: 'Основные',
  },
  {
    key: TariffPlanType.corporate,
    label: 'Корпоративные',
  },
];

const PRICE_LABELS = ['Юридический аудит', 'Онлайн-показ', 'Сопровождение'];

const TariffsListBasic = observer(
  ({
    setCurrentSlide,
    items,
    onSubscription,
    isLoading,
    openSupportModal,
  }: {
    setCurrentSlide: React.Dispatch<React.SetStateAction<number>>;
    items: TariffModel[];
    onSubscription: (id: number, option: number) => void;
    isLoading?: boolean;
    openSupportModal: () => void;
  }) => {
    const { width = window.innerWidth } = useWidth();
    const _items = useMemo(() => {
      const isMobile = width < 980;
      return items.map((tariff) => {
        const prices = tariff.pricing_options.map((option) => ({
          label:
            option.price !== '0' && option.price !== '0.00'
              ? (isMobile
                  ? `Стоимость ${option.billing_cycle === TariffPricingBillingCycle.free ? '' : 'в '}${DICTIONARY_BILLING_CYCLE[option.billing_cycle]} `
                  : '') +
                numberWithSpaces(parseInt(option.price)) +
                ' ₽'
              : 'Бесплатно',
        }));
        if (prices.length === 1) {
          prices.push({ label: 'Бесплатно' });
        } else if (prices.length === 0) {
          prices.push(...[{ label: 'Бесплатно' }, { label: 'Бесплатно' }]);
        }

        let title = 'UNSET';
        console.log(tariff)
        if(tariff.price_text){
          title = tariff.price_text;
        } else if (tariff.pricing_options[0]) {
          if (parseInt(tariff.pricing_options[0].price) === 0) {
            title = 'Free';
          } else {
            title =
              numberWithSpaces(parseInt(tariff.pricing_options[0].price)) +
              ' ₽ в ' +
              DICTIONARY_BILLING_CYCLE[tariff.pricing_options[0].billing_cycle];
          }
        }

        const optionId = tariff.pricing_options[0] ? tariff.pricing_options[0].id : -1;
        const pricesCard = [];
        for (let i = 0; i < 2; i++) {
          if (tariff.additional_services[i] !== undefined) {
            pricesCard.push({
              icon: isMobile ? <CheckCircleFilled className={s.tariff__icon} /> : undefined,
              label:
                (isMobile ? PRICE_LABELS[i] + ' ' : '') +
                (tariff.additional_services[i].free_uses && tariff.additional_services[i].free_uses > 0
                  ? `${tariff.additional_services[i].free_uses} раза бесплатно, <br/> потом ${numberWithSpaces(parseInt(tariff.additional_services[i].cost))} ₽`
                  : numberWithSpaces(parseInt(tariff.additional_services[i].cost)) + ' ₽'),
            });
          } else {
            pricesCard.push({ label: '—' });
          }
        }

        return (
          <TariffCard
            key={tariff.id}
            header={{
              subtitle: tariff.name,
              title: title,
              description: tariff.description ? tariff.description : '',
              onClick: () => {
                onSubscription(tariff.id, optionId);
              },
              isLoading,
              priceText: tariff.price_text || '',
            }}
            background={tariff.image || ''}
            hoverFixed={tariff.image !== null && tariff.image !== ''}
            list={[
              tariff.access_to_objects
                ? {
                    icon: <CheckCircleFilled className={s.tariff__icon} />,
                    label: isMobile ? 'Просмотр адреса объекта' : undefined,
                  }
                : { label: '—' },
              tariff.access_to_source
                ? {
                    icon: <CheckCircleFilled className={s.tariff__icon} />,
                    label: isMobile ? 'Прямая ссылка на ЭТП' : undefined,
                  }
                : { label: '—' },
              tariff.robot_assistant
                ? {
                    icon: <CheckCircleFilled className={s.tariff__icon} />,
                    label: isMobile ? 'Умный поиск ' : undefined,
                  }
                : { label: '—' },
              tariff.support_service
                ? {
                    icon: <CheckCircleFilled className={s.tariff__icon} />,
                    label: isMobile ? 'Приоритетная служба поддержки' : undefined,
                  }
                : { label: '—' },
              ...pricesCard,
              tariff.number_of_devices
                ? {
                    icon: isMobile ? <CheckCircleFilled className={s.tariff__icon} /> : undefined,
                    label: (isMobile ? 'Количество устройств ' : '') + `${tariff.number_of_devices}`,
                  }
                : { label: '—' },
              ...prices,
            ]}
            onClickCall={openSupportModal}
            clsx={{
              card: s.tariff__card,
            }}
            hideAction={tariff.name === 'Corp Max'}
            border
            center
          />
        );
      });
    }, [items, width]);
    return (
      <>
        {width >= 1280 ? (
          _items
        ) : (
          <Slider
            slides={_items}
            onSlideChange={(sw) => {
              setCurrentSlide(sw.activeIndex);
            }}
            className={s.tariff__list}
            slideClass={s.tariff__list__item}
            breakpoints={{
              0: {
                spaceBetween: 5,
                slidesPerView: 1.1,
              },
              479: {
                spaceBetween: 10,
                slidesPerView: 1.4,
              },
              575: {
                spaceBetween: 10,
                slidesPerView: 1.9,
              },
              768: {
                spaceBetween: 10,
                slidesPerView: 2.7,
              },
              980: {
                spaceBetween: 15,
                slidesPerView: 3.5,
              },
              1279: {
                spaceBetween: 35,
                slidesPerView: 4,
              },
            }}
          />
        )}
      </>
    );
  },
);

const key = 'tariffs_notif';

export const TariffsList = observer(({ className = '' }: { className?: string }) => {
  const { userStore, authStore, analyticsStore } = useRootStore();
  const { tariffs, subscriptionTariff, loadingSubscriptionStage } = useTariffsStore();
  const { width = window.innerWidth } = useWidth();
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [activeTab, setActiveTab] = React.useState<TariffPlanType>(TariffPlanType.personal);
  const { open: openSupportModal, close: closeSupportModal, opened: openedSupportModal } = useToggleState();
  const tabItems = React.useMemo(() => {
    console.log(tariffs);
    return tariffs.items.filter((item) => item.plan_type === activeTab && item.is_trial !== true);
  }, [tariffs.items, activeTab]);

  const [api, contextHolder] = notification.useNotification();

  const notificationShow = (title: string, description: string, isError?: boolean) => {
    api.info({
      message: title,
      description: description,
      placement: 'topRight',
      icon: isError ? <WarningFilled style={{ color: 'red' }} /> : <CheckCircleFilled style={{ color: 'green' }} />,
      key,
    });
  };

  const handleLoginButtonClick = React.useCallback(() => {
    authStore.popupController.open();
    authStore.mode.change('login');
    analyticsStore.trackEvent({ event: AnalyticsEvent.loginButtonClick });
  }, [authStore.popupController, analyticsStore]);

  const onSubscription = (id: number, option: number) => {
    if (userStore.user?.userId) {
      subscriptionTariff({
        user: userStore.user?.userId,
        plan_option: option,
        tariff: id,
      }).then((res) => {
        if (!res.isError) {
          window.location.href = res.data.payment_url;
        } else {
          notificationShow('Ошибка!', res?.data || 'Error 500', true);
        }
      });
    } else {
      handleLoginButtonClick();
    }
  };

  const onChange = (key: string) => {
    setActiveTab(key as TariffPlanType);
  };

  useEffect(() => {
    setCurrentSlide(0);
  }, [activeTab]);

  if (tariffs.loadingStage.isLoading) {
    return (
      <div className={s.loading}>
        <Spin size="large" />
      </div>
    );
  }

  if (tariffs.loadingStage.isFinished && tariffs.loadingStage.isError) {
    return <Navigate to={'/'} />;
  }

  return (
    <div className={cn(className, s.tariffs__container)}>
      {contextHolder}
      <SupportModal opened={openedSupportModal} close={closeSupportModal} />
      <div className={cn(s.tariffs__tabs)}>
        <Tabs defaultActiveKey={TariffPlanType.personal} items={itemsTabs} onChange={onChange} />
      </div>
      <div className={s.tariffs__row}>
        {width >= 980 && (
          <TariffCard
            list={[
              {
                label: 'Просмотр адреса объекта',
              },
              {
                label: 'Прямая ссылка на ЭТП',
              },
              {
                label: 'Умный поиск',
              },
              {
                label: 'Приоритетная служба поддержки',
              },
              {
                label: 'Юридический аудит',
              },
              {
                label: 'Онлайн-показ',
              },
              {
                label: 'Количество устройств',
              },
              {
                label: 'Стоимость в месяц',
              },
              {
                label: 'Стоимость в год',
              },
            ]}
          />
        )}

        <TariffsListBasic
          items={tabItems}
          setCurrentSlide={setCurrentSlide}
          onSubscription={onSubscription}
          isLoading={loadingSubscriptionStage.isLoading}
          openSupportModal={openSupportModal}
        />
      </div>
      {width < 768 && (
        <div className={s.pagination}>
          {tabItems.map((item, index) => (
            <div
              key={item.id}
              className={`${s.pagination__item} ${currentSlide === index ? s.pagination__item__active : ''}`}
            ></div>
          ))}
        </div>
      )}
    </div>
  );
});
