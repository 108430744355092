import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { Wrapper } from 'components/Wrapper';
import LogoIcon from 'components/icons/Logo';
import { RouteFullPath, routerUrls } from 'config/routes';

import { HeaderMenu } from './HeaderMenu';
import { HeaderActions } from './HeaderActions';

import s from './Header.module.scss';
import { useWidth } from 'hooks/useWidth';
import { MenuOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { HeaderActionButtons } from './HeaderActions/HeaderActions';
import InIcon from 'components/icons/InIcon';
import UserIcon from 'components/icons/User';
import { useRootStore } from 'stores/RootStore';
import { ProfileNotificationMenu } from 'components/profile/ProfileNotification/ProfileNotification';

type HeaderProps = {
  className?: string;
  offset?: boolean | number;
};

const Header = ({ className, offset = false }: HeaderProps): ReactElement<HeaderProps> => {
  const { width = window.innerWidth } = useWidth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMenuHidden, setIsMenuHidden] = useState(false);
  const scrollPrev = useRef(0);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const { userStore, notificationStore } = useRootStore();
  const navigate = useNavigate();

  const notificationMenu = React.useMemo(() => {
    return (
      <ProfileNotificationMenu
        menu
        onClick={() => {
          navigate(RouteFullPath.notification);
        }}
      />
    );
  }, [notificationStore.currentTotalItems]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollCurrent = window.scrollY;
      if (scrollCurrent > scrollPrev.current && scrollCurrent > 0) {
        setIsMenuHidden(true);
      } else {
        setIsMenuHidden(false);
      }
      scrollPrev.current = scrollCurrent;
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <header className={cn(s.header, className, isMenuHidden && s.header__hide)}>
        <Wrapper className={s.header__inner}>
          <Link className={s.header__logo} to={'/'} aria-label="КупиЛот">
            <LogoIcon variant="primary" />
          </Link>
          {width >= 1280 && <HeaderMenu className={s.header__menu} />}
          {width >= 1280 ? (
            <HeaderActions />
          ) : (
            <div className={s.actions__auth}>
              {userStore.authorized && notificationMenu}
              <button
                className={s.menuBtn}
                onClick={() => {
                  setIsModalOpen((prev) => !prev);
                }}
              >
                <MenuOutlined />
              </button>
            </div>
          )}

          <Modal
            className={s.overlay}
            open={isModalOpen}
            onClose={handleCancel}
            onCancel={handleCancel}
            maskClosable
            footer={<div />}
            wrapClassName={s.modalWrap}
            rootClassName={s.rootModal}
            maskStyle={{
              backgroundColor: 'rgba(25, 25, 25, 0.10)',
              backdropFilter: 'blur(2.5px)',
            }}
          >
            <Wrapper>
              {userStore.authorized ? (
                <Link to={RouteFullPath.profile} className={s.user__card}>
                  <div className={s.logo}>
                    <UserIcon />
                  </div>
                  <div className={s.info}>
                    <p className={s.name}>
                      {userStore.user?.firstName || ''} {userStore.user?.lastName || ''}
                    </p>
                    <p className={s.text}>Личный кабинет</p>
                  </div>
                </Link>
              ) : (
                <HeaderActionButtons
                  className={s.modal__actions}
                  icons={{
                    first: <InIcon />,
                    last: <UserIcon />,
                  }}
                  btnStyles={{
                    first: s.btnFirst,
                    last: s.btnLast,
                  }}
                  onCallbackBtn={handleCancel}
                />
              )}

              <div>
                <HeaderMenu className={s.header__menu} mode="inline" inlineIndent={0} />
              </div>
            </Wrapper>
          </Modal>
        </Wrapper>
      </header>
      {offset !== undefined && offset !== false && (
        <div style={{ padding: typeof offset === 'boolean' && offset ? 50 : (offset as number) }}></div>
      )}
    </>
  );
};

export default observer(Header);
